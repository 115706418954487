.dropdown {
  &__container {
    position: relative;
    cursor: pointer;
  }

  &__anchor {
    &:hover {
      transition: color .2s;
      color: #1177d1;
    }
  }

  &__menu {
    position: absolute;
    z-index: 20;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 4px;
    background-color: #004e92;
    border-radius: 4px;
  }

  &__button {
    all: unset;
    display: block;
    padding: 6px;
    min-width: 180px;
    font-size: 14px;
    color: #ffffff;
    transition: background-color .2s;

    &:hover {
      background-color: #1c5e97;
    }

    &:focus {
      outline: none;
    }
  }
}
