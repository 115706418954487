.permission {
  margin: 100px auto;
  &__title {
    color: #363795
  }
  &__img {
    display: block;
    width: 510px;
    margin: 0 auto;
  }
}
