.side-bar {
  display: block;
  max-width: 315px;
  min-height: 100vh;
  padding: 0 10px;
  background: linear-gradient(to right, #005c97, #363795);
  &__mobile {
    display: none;
  }
  &__button {
    display: none;
    width: 32px;
    height: 32px;
    position: relative;
    background: no-repeat center url("../../images/menu.png");
    outline: none;
    border: none;
  }
  &__logo {
    display: block;
    margin: 5vh auto;
  }
  &__calendar {
    width: 240px;
    margin: 0 10px;
  }
  &__logout,
  &__link {
    display: block;
    width: 80%;
    border: none;
    border-radius: 5px;
    outline: none;
    background: #fff;
    color: #243b55;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    transition: background 300ms ease;
  }
  &__link {
    margin: 10vh auto 0;
    &:hover {
      background: #ff8c00;
      color: #fff;
    }
  }
  &__logout {
    margin: 40px auto 0;
    &:hover {
      background: #cc1313;
      color: #fff;
    }
  }
}

@media (max-width: 1250px) {
  .side-bar {
    &__calendar {
      width: 240px;
    }
  }
}

@media (max-width: 1050px) {
  .side-bar {
    &__desktop {
      display: none;
    }
    &__logo {
      margin: 0 auto 10px;
    }
    &__link {
      margin: 10px auto;
    }
    &__mobile {
      display: block;
    }
    &__button {
      display: block;
      margin: 20px auto 0;
    }
  }
}

@media (max-width: 750px) {
  .side-bar {
    height: 100vh;
    &__button {
      width: 30px;
    }
  }
}

@media (max-width: 550px) {
  .side-bar {
    height: 100vh;
    &__button {
      width: 30px;
      height: 30px;
    }
  }
}

@media (max-width: 400px) {
  .side-bar {
    height: 100vh;
    padding: 0 3px;
  }
}
