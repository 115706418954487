.projectsBoard {
  &__container {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    max-width: 1720px;
    margin: 0 auto;
  }
  &__main {
    display: block;
    max-width: 1400px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
  }
  &__body {
    display: grid;
    grid-template-columns: 0.8fr;
    &--digis,
    &--it {
      display: block;
      width: 100%;
      color: #fff;
      background-color: #ff8c00;
      padding: 0 15px;
      margin-bottom: 20px;
      font-size: 21px;
      font-weight: bold;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    &--it {
      background: linear-gradient(to right, #000428, #004e92);
    }
  }
  &__row {
    display: block;
    margin: 0 auto 20px;
    line-height: 80px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    background: linear-gradient(to right, #005c97, #363795);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.loader {
  display: block;
  width: 300px;
  height: 300px;
  margin: 20vh auto;
}

@media (max-width: 1200px) {
  .projectsBoard {
    &__day {
      font-size: 24px;
    }
    &__body {
      &--digis,
      &--it {
        padding: 0 10px;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: bold;
      }
      &--it {
        width: calc(100% - 60px);
      }
    }
  }
}

@media (max-width: 750px) {
  .projectsBoard {
    &__day {
      font-size: 20px;
    }
    &__body {
      &--digis,
      &--it {
        padding: 0 10px;
        margin-bottom: 20px;
        font-size: 14px;
      }
      &--it {
        width: calc(100% - 50px);
      }
    }
  }
}
