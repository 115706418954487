.projectIt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 0.9em;
  background: #fffafa;
  font-family: sans-serif;
  &__body {
    width: 35vw;
    max-width: 600px;
    border-top-right-radius: 15px;
  }
  &__title {
    padding: 0 10px;
    background: #004e92;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #fff;
    line-height: 30px;
    text-align: left;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &__icon {
      width: 20px; 
      height: 20px;

      cursor: pointer;
    }

    &__indicator {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 14px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: #cc1313;

      cursor: default;
    }

    &--right {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  &__headers,
  &__input {
    display: block;
    text-align: center;
    line-height: 25px;
  }
  &__headers {
    background-color: #009879;
    color: #fff;
    &--small {
      width: 10%;
      border-left: thin solid #fff;
      border-right: thin solid #fff;
    }
    &--medium {
      width: 30%;
    }
    &--large {
      width: 60%;
    }
  }

  &__warning {
    display: block;
    width: 100%;
    text-align: center;
    background: #f08080;
  }

  &__tasks {
    padding: 0;
    margin: 0;
  }

  &__task {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #fffafa;
    &--user,
    &--hours,
    &--notes {
      display: block;
      border: none;
      outline: none;
      text-align: center;
      border: 1px solid rgba(119, 136, 153, 50%);
      background-color: inherit;
    }
    &--hours,
    &--notes {
      &:hover,
      &:focus {
        border: 1px solid #cc1313;
      }
    }
    &--hours {
      width: 10%;
    }
    &--user {
      width: 30%;
      cursor: default;
    }
    &--notes {
      width: 60%;
    }
    &--blacklisted {
      background-color: #e2dede;
    }
  }
  &__button {
    display: flex;
    align-self: center;
    width: 50px;
    height: 50px;
    margin: 0 20px 0;
    border: none;
    outline: none;
    background: url("/images/arrow.svg") center no-repeat;
    background-color: #80f0e72d;
    background-size: contain;
    transition: transform 300ms;
    &:hover {
      transform: scale(1.1);
    }
  }
}

@media (max-width: 1200px) {
  .projectIt {
    &__title {
      font-size: 16px;
      padding: 2px;
      line-height: 18px;
    }
    &__headers,
    &__input {
      font-size: 13px;
      line-height: 20px;
    }
    &__button {
      width: 40px;
      height: 40px;
      margin: 0 10px;
    }
    &__task {
      font-size: 12px;
      &--hours {
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 750px) {
  .projectIt {
    font-size: 10px;
    margin-bottom: 15px;
    &__title {
      font-size: 10px;
      padding: 2px;
      line-height: 14px;
    }
    &__headers,
    &__input {
      font-size: 10px;
      line-height: 12px;
    }
    &__headers {
      &--small {
        width: 20%;
      }
      &--medium {
        width: 35%;
      }
    }
    &__button {
      width: 30px;
      height: 30px;
      margin: 0 10px;
    }
    &__task {
      font-size: 8px;
      &--hours {
        font-weight: bold;
        width: 22%;
      }
    }
  }
}

.projectDigis {
  &__body {
    width: 35vw;
    max-width: 600px;
    border-top-right-radius: 15px;
  }
  &__title {
    padding: 0 10px;
    background: #004e92;

    color: #fff;
    line-height: 30px;
    text-align: left;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  &__headers,
  &__input {
    display: block;
    text-align: center;
    line-height: 25px;
  }
  &__headers {
    background-color: #009879;
    color: #fff;
    &--small {
      width: 10%;
      border-left: thin solid #fff;
      border-right: thin solid #fff;
    }
    &--medium {
      width: 30%;
    }
    &--large {
      width: 60%;
    }
  }

  &__tasks {
    padding: 0;
    margin: 0;
  }

  &__task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    overflow: hidden;
    &--user,
    &--hours,
    &--notes {
      display: block;
      border: none;
      outline: none;
      text-align: center;
      border: 1px solid rgba(119, 136, 153, 50%);
      cursor: default;
    }
    &--hours {
      width: 10%;
    }
    &--user {
      width: 30%;
    }
    &--notes {
      width: 60%;
    }
  }
  &__warning {
    display: block;
    width: 100%;
    text-align: center;
    background: #f08080;
  }
}

@media (max-width: 1200px) {
  .projectDigis {
    &__title {
      font-size: 16px;
      padding: 2px;
      line-height: 18px;
    }
    &__headers,
    &__input {
      font-size: 13px;
      line-height: 20px;
    }
    &__button {
      width: 40px;
      height: 40px;
      margin: 0 10px;
    }
    &__task {
      height: 40px;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      &--hours {
        font-weight: bold;
      }
      &--notes {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 750px) {
  .projectDigis {
    font-size: 10px;
    margin-bottom: 15px;
    &__title {
      font-size: 10px;
      padding: 2px;
      line-height: 14px;
    }
    &__headers,
    &__input {
      font-size: 10px;
      line-height: 12px;
    }
    &__headers {
      &--small {
        width: 20%;
      }
      &--medium {
        width: 35%;
      }
    }
    &__button {
      width: 30px;
      height: 30px;
      margin: 0 10px;
    }
    &__task {
      font-size: 8px;
      &--hours {
        font-weight: bold;
        width: 22%;
      }
    }
  }
}

.projectIt__button {
  &--personalEntry {
    position: absolute;
    left: -16px;
    display: flex;
    align-self: center;
    width: 36px;
    height: 36px;
    margin: 0;
    border: none;
    outline: none;
    background: url("/images/arrow-1.svg") center no-repeat;
    transform: scale(0.55);
    background-color: #80a5f02d;
    background-size: contain;
    transition: transform 300ms;
    &:hover {
      transform: scale(0.64);
    }
  }
}

.personalEntryTooltip {
  color: #000000 !important;
  background-color: #80a5f02d !important;
  &.place-top {
    &:after {
      border-top-color: #80a5f02d !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
}

.allProjectTooltip {
  color: #000000 !important;
  background-color: #dbf5f318 !important;
  &.place-top {
    &:after {
      border-top-color: #dbf5f318 !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
}
